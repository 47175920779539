import React from "react"
import BaseIcon from "./BaseIcon";

export default function SubtractionIcon() {
    return (
        <BaseIcon
            width={40}
            height={9}
        >
            <path d="M37.1429 0H2.85714C1.27946 0 0 1.27946 0 2.85714V5.71429C0 7.29197 1.27946 8.57143 2.85714 8.57143H37.1429C38.7205 8.57143 40 7.29197 40 5.71429V2.85714C40 1.27946 38.7205 0 37.1429 0Z" />
        </BaseIcon>
    )
}

