import modelRequest, { RequestError } from './lib/modelRequest'

/**
 * Authentication of user
 */
interface Auth {
  /**
   * User type
   */
  user: 'guest' | 'customer' | 'student'

  /**
   * React Version
   */
  version: number
}

export default {
  /**
   * Gets the authentication of the user
   */
  get: (): Promise<Auth & RequestError> => {
    return modelRequest.get('/api/auth')
  },
}
