import React from "react"
import * as models from "../models/index"
import { DonutReadingResponse } from "../models/donuts"
import DonutChart from "./DonutChart"
import PhrasesIcon from "./DonutIcons/Phrases"

export default class PhrasesDonut extends React.Component<{
  studentID?: string
  onLoaded?: (donutsResponse: DonutReadingResponse) => void
  refresh?: boolean
}, {
  donutData: DonutReadingResponse
  loading: boolean
}> {
  state = {
    loading: true,
    donutData: null
  }
  componentDidMount() {
    this.loadDonuts()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.refresh && this.props.refresh) {
      this.loadDonuts()
    }
  }

  loadDonuts = async () => {
    const donutData = await models.donuts.getReadingProgress({
      studentID: this.props.studentID,
    })

    if (donutData.valid) {
      this.setState({donutData, loading: false}, ()=>{
        if (this.props.onLoaded) {
          this.props.onLoaded(donutData)
        }
      })
    }
  }

  render() {

    if (this.state.loading) {
      return <></>
    }

    var { phrases } = this.state?.donutData
    return (
      <div id="donut-charts" className="text-center">
        <DonutChart
            label="Phrases"
            total={phrases.total}
            completed={phrases.completed}
            baseColor={"#0055BB"}
            Icon={PhrasesIcon}
          />
      </div>
    )
  }
}
