import React from "react"
import * as d3 from "d3"

interface Props {
    total: number
    completed: number
    label: string
    percentageLine?: number
    baseColor: string
    Icon: React.FC
}

const WIDTH = 120,
    HEIGHT = 180,
    THICKNESS = 20,
    RADIUS = Math.min(WIDTH, HEIGHT) / 2

export default function DonutChart({ total, completed, label, percentageLine, baseColor, Icon }: Props) {

    let pieValues = React.useMemo(() => {
        let pie = d3
            .pie()
            .sort(null)
        
        let percentComplete = (completed / total) * 100
        return pie([percentComplete, 100 - percentComplete])
    }, [completed, total])

    let arc = React.useMemo(() => {
        return d3
            .arc()
            .innerRadius(RADIUS - THICKNESS)
            .outerRadius(RADIUS)
    }, [])

    return (
        <svg
            width={WIDTH}
            height={HEIGHT}
            className="pie"

        >
            <text textAnchor="middle" x={WIDTH / 2} y="20">{label}</text>
            <text textAnchor="middle" x={WIDTH / 2} y={HEIGHT - 10}>{completed + "/" + total}</text>
            <g transform={`translate(${WIDTH / 2}, ${HEIGHT / 2})`}>
                <g fill={baseColor}>
                    <Icon />
                </g>


                <path
                    d={arc(pieValues[0])}
                    fill={baseColor}
                />

                <path
                    d={arc(pieValues[1])}
                    opacity={.1}
                    fill={baseColor}
                />
                {percentageLine && <>
                    {/* 
                        x/y coordinates on the unit circle are found by using r*cos(A) = x and r*sin(A) = y, but coordinate translation from cos/sin to SVG is needed.
                        cos/sin have 0° at the right of the circle, sweeps counterclockwise, and have +y up with the origin at the bottom left.
                        SVG sweeps clockwise, and has +y down with the origin at the top left of the screen. Our application also requires 0° to be at the top.
                        To translate, we start the angle at 90° for the top of the circle, then subtract the given angle to switch from counterclockwise to clockwise.
                        After getting the sin, we negate the value to adjust for the direction of the +y axis.
                    */}
                    <line
                        x1={Math.cos((90 - (percentageLine/100)*360) * Math.PI/180) * (RADIUS - THICKNESS)}
                        y1={-Math.sin((90 - (percentageLine/100)*360) * Math.PI/180) * (RADIUS - THICKNESS)}
                        x2={Math.cos((90 - (percentageLine/100)*360) * Math.PI/180) * RADIUS}
                        y2={-Math.sin((90 - (percentageLine/100)*360) * Math.PI/180) * RADIUS}
                        stroke="black"
                        strokeWidth="3px"
                    />
                </>}
            </g>
        </svg>
    )
}
