import React from 'react'
import Response from './Response'
import BeatLoader from 'react-spinners/BeatLoader'

function Alphabet({
    responses,
    exerciseNumber,
    title,
    sectionClass,
    groupLength,
    onClick = () => {},
    onDoubleClick = () => {},
    hideGhosting = false,
    Help = () => <React.Fragment />
  }) {
    var blockBootstrapClass = ''
    if (sectionClass === 'large') {
        if (groupLength === 1) blockBootstrapClass = 'col-6 col-sm-3 col-md-3'
        else if (groupLength === 2) blockBootstrapClass = 'col-3'
        else if (groupLength === 3) blockBootstrapClass = 'col-6 col-sm-3'
        else
        console.error(
            `Unknown combination of section and group length class: ${sectionClass}, ${groupLength}`
        )
    } else if (sectionClass === 'small') {
        if (groupLength === 4) blockBootstrapClass = 'col-3'
        else
        console.error(
            `Unknown combination of section and group length class: ${sectionClass}, ${groupLength}`
        )
    } else {
        console.error(`Unknown section class: ${sectionClass}`)
    }

    return (
        <React.Fragment>
        {responses.length === 0 ? (
            <div style={{ margin: '0 auto', display: 'table', height: '50vh' }}>
            <div
                style={{
                display: 'table-cell',
                verticalAlign: 'middle',
                textAlign: 'center',
                }}
            >
                <BeatLoader size={15} color={'#123abc'} loading={true} />
            </div>
            </div>
        ) : (
            <React.Fragment>
            <h1 className={'h3 mb-4 pb-3'}>
                Alphabet Exercise {exerciseNumber} {title ? "-" : ""} {title}
                <Help />
            </h1>

            <div
                id="probe-contents" //may need to be changed to a class or renamed
                className={`row content-block${
                sectionClass === 'small' ? '-small' : ''
                } alphabet-text`}
            >
                {responses.map((response, index) => {
                return (
                    <div
                    key={index}
                    onClick={onClick.bind(null, response.number, {
                        responseNumber: response.number,
                    })}
                    onDoubleClick={onDoubleClick.bind(null, response.number)}
                    className={`${blockBootstrapClass} response-container`}
                    >
                    <Response
                        hadPreviousError={!hideGhosting && response.previousError}
                        response={response.response}
                        responseNumber={response.number}
                    />
                    </div>
                )
                })}
            </div>
            </React.Fragment>
        )}
        </React.Fragment>
    )
  }

  export default Alphabet
