import axios from 'axios'

interface RequestError {
  valid: false | undefined
  message: "There has been an error connecting to the server." | "You don't appear to be online." | string | undefined
}

const transport = axios.create({
  withCredentials: true,
  headers: { Pragma: 'no-cache' },
})

let configuredUrl = ''

function type(requestType) {
  return (url, data = {}, ...parm) => {
    return transport[requestType](configuredUrl + url, data, ...parm)
    .then(({ data }) => {
      return Promise.resolve(data)
    })
    .catch((error) => {
        var message = error?.response?.data?.message ?? "There has been an error connecting to the server."

        if (!window.navigator.onLine) {
            message = "You don't appear to be online."
        }
    
        return Promise.resolve({
            valid: false,
            message: message
        })
      }
    )
  }
}

export {
  RequestError
}

export default {
  get: type('get'),
  post: type('post'),
  delete: type('delete'),
  put: type('put'),
  configure: function configure(url: string) {
    configuredUrl = url
  },
  axios: transport
}
