import React from "react"


const BaseIcon: React.FC<{ width: number, height: number }> = ({ width, height, children }) => {
    return (
        <g
            transform={`translate(-${width/2}, -${height/2})`}
        >
            {children}
        </g>
    )
}

export default BaseIcon
