import React from "react"
import BaseIcon from "./BaseIcon";

export default function PhonicsIcon() {
    return (
        <BaseIcon
            width={48}
            height={40}
        >
            <path d="M17.8144 4.58767L10.4436 11.9998H1.9883C0.889766 11.9998 0 12.8948 0 13.9997V25.9992C0 27.1033 0.889766 27.9991 1.9883 27.9991H10.4436L17.8144 35.4112C19.0596 36.6637 21.2086 35.7837 21.2086 33.9971V6.00177C21.2086 4.21352 19.0579 3.3369 17.8144 4.58767ZM37.144 0.331204C36.2186 -0.279601 34.9751 -0.0221127 34.3678 0.910343C33.7597 1.84113 34.0182 3.09191 34.9436 3.70271C40.4338 7.32671 43.7104 13.4189 43.7104 20.0003C43.7104 26.5816 40.4338 32.6739 34.9436 36.2978C34.0182 36.9078 33.7597 38.1594 34.3678 39.0894C34.9511 39.9818 36.1846 40.3027 37.144 39.6685C43.7651 35.2971 47.7193 27.9432 47.7193 19.9994C47.7193 12.0557 43.7651 4.70266 37.144 0.331204ZM39.7661 19.9994C39.7661 14.7055 37.11 9.83826 32.6604 6.98006C31.7333 6.38509 30.5039 6.66174 29.9165 7.6017C29.3291 8.54165 29.6034 9.78576 30.5304 10.3816C33.8235 12.4973 35.7895 16.0921 35.7895 19.9994C35.7895 23.9068 33.8235 27.5016 30.5304 29.6173C29.6034 30.2123 29.3291 31.4564 29.9165 32.3972C30.4558 33.2605 31.6662 33.6588 32.6604 33.0188C37.11 30.1606 39.7661 25.2942 39.7661 19.9994ZM28.021 13.5939C27.0616 13.0664 25.8513 13.4139 25.3194 14.3814C24.79 15.3488 25.1404 16.5646 26.1023 17.0987C27.1718 17.6896 27.8363 18.802 27.8363 19.9994C27.8363 21.1977 27.1718 22.3093 26.1031 22.9001C25.1413 23.4343 24.7908 24.6501 25.3202 25.6175C25.8529 26.5891 27.0641 26.9341 28.0218 26.405C30.3606 25.1092 31.8137 22.6552 31.8137 19.9986C31.8137 17.3421 30.3606 14.8889 28.021 13.5939Z" />
        </BaseIcon>
    )
}
