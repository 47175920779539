import React from 'react'
import Response from './Response'
import BeatLoader from 'react-spinners/BeatLoader'

function Phonics({
  responses: phonicsSections,
  exerciseNumber,
  title,
  sectionClass,
  groupLength,
  onClick = () => {},
  onDoubleClick = () => {},
  hideGhosting = false,
  Help = () => <React.Fragment />
}) {

  let fontClass = "font-md"
  let blockBootstrapClass = ''

  if(exerciseNumber < 18) {
    fontClass = "font-lg"
  }
  
  if (sectionClass === 'large') {
    if (groupLength === 1) {
      if(exerciseNumber > 76) { // words get too long for 3 columns
        blockBootstrapClass = 'col-6 col-sm-4'
      }
      else {
        blockBootstrapClass = 'col-6 col-sm-4 col-md-3'
      }
    }
    else if (groupLength === 2) blockBootstrapClass = 'col-3'
    else if (groupLength === 3) blockBootstrapClass = 'col-6 col-sm-4'
    else
      console.error(
        `Unknown combination of section and group length class: ${sectionClass}, ${groupLength}`
      )
  } else if (sectionClass === 'small') {
    if (groupLength === 4) blockBootstrapClass = 'col-3'
    else
      console.error(
        `Unknown combination of section and group length class: ${sectionClass}, ${groupLength}`
      )
  } else {
    console.error(`Unknown section class: ${sectionClass}`)
  }

  const loader = (<div className='d-table m-0' style={{ height: '50vh' }}>
    <div className='d-table-cell text-center align-middle'>
      <BeatLoader size={15} color={'#123abc'} loading={true} />
    </div>
  </div>)

  return (
    <>
      {phonicsSections.length === 0 ? loader : (
        <>
          <h1 className={'h3 mb-4'}>
            Phonics Exercise {exerciseNumber} - {title}
            <Help />
          </h1>
          <div id="probe-contents" className={`row content-block ${sectionClass} phonics-text ${fontClass}`}>
          {phonicsSections.map((phonicsSection) => {
                if(phonicsSection.type?.toLowerCase() === "sentence") {
                  return(
                    <p className='text-start ms-4 ms-lg-auto'>
                      {phonicsSection.responses.map((response,i) => {
                        return(
                          <Response
                            hadPreviousError={!hideGhosting && response.previousError}
                            response={response.response}
                            responseNumber={response.number}
                            zeroWidthSpace={true}
                            nextResponse={
                              phonicsSection.responses[i + 1]?.response
                            }
                            noHyphenedSpacing
                            onClick={onClick.bind(null, response.number, {
                              responseNumber: response.number,
                            })}
                            onDoubleClick={onDoubleClick.bind(null,response.number)}
                          />
                        )
                      })}
                    </p>
                  )
                }
                else {
                  return phonicsSection.responses.map((response,i) => 
                    <div
                      onClick={onClick.bind(null, response.number, {
                        responseNumber: response.number,
                      })}
                      onDoubleClick={onDoubleClick.bind(null, response.number)}
                      className={`${blockBootstrapClass} response-container`}
                    >
                      <Response
                        hadPreviousError={!hideGhosting && response.previousError}
                        response={response.response}
                        responseNumber={response.number}
                        />
                    </div>
                  )
                }
            })}
          </div>
        </>
      )}

    </>
  )
}

export default Phonics
