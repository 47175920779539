import React from 'react'
import ClipLoader from 'react-spinners/ClipLoader'

/**
 * Student Login Component used for logging students in
 */
class StudentLogin extends React.Component<{
  submit: Function
  submitting: boolean
}> {
  private username = React.createRef<HTMLInputElement>()
  private studentCode = React.createRef<HTMLInputElement>()

  submit = (event) => {
    event.preventDefault()
    this.props.submit(
      this.username.current.value,
      this.studentCode.current.value
    )
  }

  render() {
    var { submitting } = this.props
    return (
      <form
        className="form-horizontal form-validate"
        noValidate
        method="post"
        action="/account/log-in/student"
        onSubmit={this.submit}
      >
        <div className="form-group row">
          <label
            htmlFor="username"
            className="d-none d-sm-inline col-sm-3 control-label text-end"
          >
            Teacher Username
          </label>

          <div className="col-sm-6">
            <input
              type="text"
              disabled={submitting}
              className="form-control mb-2"
              id="username"
              name="username"
              placeholder="Teacher Username"
              data-description="enter your username"
              ref={this.username}
              required
              autoFocus
            />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="code" className="d-none d-sm-inline col-sm-3 control-label text-end">
            Student Code
          </label>

          <div className="col-sm-6">
            <div className="input-group">
              <input
                type="text"
                disabled={submitting}
                className="form-control mb-2 string"
                id="password"
                name="code"
                placeholder="Student Code"
                data-type="student code"
                minLength={5}
                maxLength={5}
                data-description="enter your student code"
                ref={this.studentCode}
                required
              />
            </div>
          </div>
        </div>

        <button
          type="submit"
          id="login-submit"
          disabled={submitting}
          className="btn btn-secondary btn-lg"
        >
          {submitting ? (
            <ClipLoader size={20} color={'#123abc'} loading={submitting} />
          ) : (
            'Log In'
          )}
        </button>
      </form>
    )
  }
}

export default StudentLogin
