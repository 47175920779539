import React from 'react'
import BeatLoader from 'react-spinners/BeatLoader'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { MathResponse, MathType } from '../models/probe'

interface Props {
  subject: MathType
  responses: MathResponse[]
  skillSet: number
  title: string
  onClick: (responseNumber: number, errorObject: { responseNumber: number, responseId: string }) => void
  onDoubleClick: (responseNumber: number) => void
  showAnswer: boolean
  Help: React.FC
}

function MathProbe({
  responses,
  subject,
  skillSet,
  title,
  showAnswer,
  onClick = () => {},
  onDoubleClick = () => {},
  Help = () => <React.Fragment />
}: Props) {

  const computeAnswer = (response: MathResponse) => {
    const operationAnswer = (res: MathResponse) => {
      switch(res.operator) {
        case "*": return res.firstOperand * res.secondOperand
        case "/": return res.firstOperand / res.secondOperand
        case "+": return res.firstOperand + res.secondOperand
        case "-": return res.firstOperand - res.secondOperand
        default: return 0
      }
    }
    return Math.round(operationAnswer(response))
  }

  const spacing: Record<MathType, number> = {
    addition: 6,
    subtraction: 6,
    division: 5,
    multiplication: 7
  }
  const COLUMNS = spacing[subject]
  const ROWS = Math.ceil(responses.length/COLUMNS)
  
  return (
    <>
      {responses.length === 0 ? (
        <div style={{ margin: '0 auto', display: 'table', height: '50vh' }}>
          <div
            style={{
              display: 'table-cell',
              verticalAlign: 'middle',
              textAlign: 'center',
            }}
          >
            <BeatLoader size={15} color={'#123abc'} loading={true} />
          </div>
        </div>
      ) : (
        <>
          <h1 className="h3 mb-4 pb-3">
            {subject.charAt(0).toUpperCase() + subject.slice(1)} Skill Set {skillSet} - {title}
            <Help />
          </h1>

          <div
            id="probe-contents"
            className="math-text"
          >
            {/*
            Instead of using exact grid numbers (ie col-3), <Col> is using the 'col' class. This will allow having a number of columns that isn't divisible by bootstraps 12 column spaces. ie 7 equally spaced columns 
            */}
            {Array.from(Array(ROWS)).map((_u, rowIndex) => (
                <Row>
                  {Array.from(Array(COLUMNS)).map((_u, columnIndex) => {
                    if(rowIndex*COLUMNS + columnIndex >= responses.length) {
                      return (<Col></Col>)
                    }
                    const response = responses[rowIndex*COLUMNS + columnIndex]
                    return (
                      <Col
                        className="response-container"
                        onClick={() => onClick(response.responseNumber, {
                          responseNumber: response.responseNumber,
                          responseId: response.id
                        })}
                        onDoubleClick={() => onDoubleClick(response.responseNumber)}
                      >
                        <div className="text-end text-end m-auto response">
                          <div className="response-text" id={`response-${response.responseNumber}`}>
                            {response.operator !== "/" ? <>
                              <div>
                                {response.firstOperand}
                              </div>
                              <div
                                style={{
                                  borderBottom: "1px solid"
                                }}
                              >
                                <span>{response.operator !== "*" ? response.operator : "x"} {response.secondOperand}</span>
                              </div>
                              <div>
                                <strong>
                                  {/* use a nbsp to always show the spacing of the answer */}
                                  &nbsp;
                                  {showAnswer && computeAnswer(response)}
                                </strong>
                              </div>
                            </> : 
                            // Long division
                            <>
                              <span>
                                <strong>
                                  {/* use a nbsp to always show the spacing of the answer */}
                                  &nbsp;
                                  {showAnswer && computeAnswer(response)}
                                </strong>
                              </span>
                              <div>
                                {response.secondOperand}
                                <span
                                  style={{
                                    borderLeft: "1px solid",
                                    borderTop: "1px solid",
                                    paddingTop: "5px",
                                    paddingRight: "5px",
                                    paddingLeft: "5px",
                                    marginLeft: "10px",
                                  }}>
                                    {response.firstOperand}
                                </span>
                              </div>
                            </>}
                          </div>
                        </div>
                      </Col>
                    )
                  })}
                </Row>
            ))}
          </div>
        </>
      )}
    </>
  )
}

export default MathProbe
