import React from 'react'
import Response from './Response'
import BeatLoader from 'react-spinners/BeatLoader'

function Phrases({
  responses,
  exerciseNumber,
  title,
  sectionClass,
  onClick = () => {},
  onDoubleClick = () => {},
  hideGhosting = false,
  Help = () => <React.Fragment />
}) {
  var blockBootstrapClass = ''
  if (sectionClass === 'extra-large') blockBootstrapClass = 'col-12 text-start'
  if (sectionClass === 'large') blockBootstrapClass = 'col-12'
  else if (sectionClass === 'medium') blockBootstrapClass = 'col-12 col-md-6 g-0 text-nowrap'
  else if (sectionClass === 'small') blockBootstrapClass = 'col-12 col-md-6 col-lg-4 g-0 text-nowrap'
  else if (sectionClass === 'extra-small') blockBootstrapClass = 'col-6 col-md-4 col-lg-3 g-0 text-nowrap'
  else console.error(`Unknown section class: ${sectionClass}`)

  let _responses = []
  for (var i = 0; i < responses.length; ) {
    var responsesList = []
    var phraseNumber
    do {
      /* 
                phraseNumber: entry['Phrase_Number'],
                responseNumber: entry['Response_Number'],
            
            */
      //error{phraseNumber: number, responseNumber: number}
      phraseNumber = responses[i].phraseNumber
      var responseList = responses[i++]
      responsesList.push(
        <Response
          onClick={onClick.bind(null, i, {
            phraseNumber: responseList.phraseNumber,
            responseNumber: responseList.responseNumber,
          })}
          onDoubleClick={onDoubleClick.bind(null, i)}
          hadPreviousError={!hideGhosting && responseList.previousError}
          response={responseList.response}
          nextResponse={responses[i]?.response}
          responseNumber={i}
          zeroWidthSpace
          noHyphenedSpacing
        />
      )
    } while (responses?.[i]?.phraseNumber === phraseNumber)

    _responses.push(
      <div className={`${blockBootstrapClass} response-container`}>
        {responsesList}
      </div>
    )
  }

  return (
    <React.Fragment>
      {responses.length === 0 ? (
        <div style={{ margin: '0 auto', display: 'table', height: '50vh' }}>
          <div
            style={{
              display: 'table-cell',
              verticalAlign: 'middle',
              textAlign: 'center',
            }}
          >
            <BeatLoader size={15} color={'#123abc'} loading={true} />
          </div>
        </div>
      ) : (
        <React.Fragment>
          <h1 className={'h3 mb-4'}>
            Sight Phrases Exercise {exerciseNumber} - {title}
            <Help />
          </h1>
          <div id="probe-contents" className={`row content-block phrases-text ${sectionClass}`}>
            {_responses}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default Phrases
