import React from 'react'
import { Link } from 'react-router-dom'
import BeatLoader from 'react-spinners/BeatLoader'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ReadingDonuts from './ReadingDonuts'
import MathDonuts from './MathDonuts'
import * as models from '../models/index'
import { SelectedProbe, ProbeDetails } from '../models/studentProbes'

interface Props {
  onError?: (message: string) => any
}

export default function StudentDashboard({ onError }: Props) {
  const [selectedProbe, setSelectedProbe] = React.useState<SelectedProbe>()
  const [loading, setLoading] = React.useState(true)

  const updateData = async () => {
    models.studentProbes.get()
    .then((data) => {
      if (data.valid == false) {
        return typeof onError === "function" && onError(data.message)
      }
      setSelectedProbe(data)
      setLoading(false)
    })
    .catch(() => {
      if (onError) {
        onError("There has been an uncaught error.")
      }
    })
  }

  React.useEffect(() => {
    updateData()
    const updateProbes = setInterval(updateData, 2000)
    return () => {
      clearInterval(updateProbes)
    }
  }, [])

  if (loading) {
    return (
      <div style={{ margin: '0 auto', display: 'table', height: '50vh' }}>
        <div
          style={{
            display: 'table-cell',
            verticalAlign: 'middle',
            textAlign: 'center',
          }}
        >
          <BeatLoader color={'#123abc'} />
        </div>
      </div>
    )
  }

  return (
    <>
      {
        selectedProbe.program === "reading" && <ReadingDonuts />
      }

      {
        selectedProbe.program === "math" && <MathDonuts />
      }

      <Row className="subheader-text">
        <Col sm={{
          span: 10,
          offset: 1
        }}>
          <p>
            Great Leaps exercises selected on the teacher device will appear
            below. When they do please click on them to open each one.
          </p>
        </Col>
      </Row>

      <Row id="student-probe-list">
        <Col sm={{
            span: 10,
            offset: 1
          }}>
          {selectedProbe.program === "reading" && <>
            <DashboardSection
              title="Phonics"
              details={selectedProbe.phonics}
            />

            <DashboardSection
              title="Sight Phrases"
              details={selectedProbe.phrases}
              style={{ marginTop: '20px' }}
            />

            <DashboardSection
              title="Stories"
              details={selectedProbe.stories}
              style={{ marginTop: '20px' }}
            />
          </>}

          {selectedProbe.program === "math" && <>
            <DashboardSection
              title="Addition"
              details={selectedProbe.addition}
            />

            <DashboardSection
              title="Subtraction"
              details={selectedProbe.subtraction}
              style={{ marginTop: '20px' }}
            />

            <DashboardSection
              title="Multiplication"
              details={selectedProbe.multiplication}
              style={{ marginTop: '20px' }}
            />

            <DashboardSection
              title="Division"
              details={selectedProbe.division}
              style={{ marginTop: '20px' }}
            />
          </>}
        </Col>
      </Row>
    </>
  )
}

interface SectionProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title: string
  details?: ProbeDetails
}

function DashboardSection({ title, details, ...rest }: SectionProps) {
  return (
    <Card>
      <Card.Header className="text-center">{title}</Card.Header>
      <Card.Body className="list-group">
        <Link
            to={details?.link}
            className="list-group-item-action text-decoration-none"
            style={details?.link == null ? { pointerEvents: 'none' } : null}
          >
            <h4 className="list-group-item-heading pt-2 pb-2 mb-0 text-center">
              <strong>
                {details?.title ?? 'No exercise selected'}
              </strong>
            </h4>
        </Link>
      </Card.Body>
    </Card>
  )
}
