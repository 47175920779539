import React from "react"

const WIDTH = 50, HEIGHT = 36, X_OFFSET = 0, Y_OFFSET = 0

export default function AlphabetIcon() {
    return (
            <g transform={`translate(-${(WIDTH/2) + X_OFFSET}, -${(HEIGHT/2) + Y_OFFSET})`}>
                <path d="M6.96652 33.0689C6.57916 33.1687 6.24692 33.1614 5.96979 33.0469C5.69265 32.9324 5.49745 32.7348 5.38418 32.4542C5.27091 32.1736 5.24454 31.8293 5.30507 31.4213L8.64637 11.7251C8.72079 11.2103 8.87841 10.8186 9.11924 10.55C9.37943 10.2765 9.69352 10.0923 10.0615 9.99751C10.4101 9.9077 10.7545 9.92225 11.0947 10.0411C11.4543 10.155 11.7906 10.4195 12.1035 10.8346L24.5082 26.4746C24.7769 26.7978 24.9316 27.1193 24.9724 27.4393C25.0082 27.7399 24.9419 28.0048 24.7736 28.234C24.6003 28.4439 24.32 28.5987 23.9326 28.6985C23.5066 28.8082 23.1403 28.789 22.8339 28.6407C22.5274 28.4925 22.2202 28.2205 21.9122 27.8249L18.6712 23.6411L20.2453 24.103L7.86932 27.2911L8.997 26.1332L8.1999 31.3571C8.12548 31.872 7.99939 32.2659 7.82163 32.5389C7.63891 32.7925 7.35387 32.9691 6.96652 33.0689ZM10.9127 13.5577L9.19427 25.091L8.28909 24.5807L18.5443 21.9389L18.055 22.8085L10.9708 13.5427L10.9127 13.5577Z"/>
                <path d="M20.6331 21.1374C20.1114 20.9982 19.7415 20.7546 19.5236 20.4066C19.3301 20.0445 19.3027 19.6025 19.4413 19.0806L24.0099 1.88721C24.1486 1.36532 24.3891 1.00516 24.7316 0.806749C25.0985 0.594162 25.5428 0.557486 26.0645 0.69672L32.9631 2.5377C34.3737 2.91415 35.5153 3.44649 36.3878 4.13473C37.2797 4.82813 37.8812 5.6407 38.1923 6.57243C38.5228 7.50932 38.5391 8.53832 38.2412 9.65942C37.9125 10.8965 37.268 11.8423 36.3077 12.4968C35.3474 13.1513 34.2007 13.456 32.8677 13.4107L32.9832 12.9758C34.3618 13.5507 35.3388 14.391 35.9141 15.4968C36.4946 16.5832 36.5974 17.8319 36.2224 19.2429C35.7396 21.0599 34.7682 22.3014 33.3082 22.9675C31.8533 23.6142 30.0438 23.6488 27.8795 23.0712L20.6331 21.1374ZM22.5248 19.1583L28.148 20.6589C29.5973 21.0457 30.7442 21.0516 31.5888 20.6767C32.4386 20.2824 33.0175 19.5055 33.3257 18.3457C33.6339 17.1859 33.5146 16.233 32.9679 15.4868C32.4212 14.7406 31.4232 14.1741 29.9739 13.7873L24.3507 12.2867L22.5248 19.1583ZM24.967 9.96719L30.1265 11.3441C31.5178 11.7154 32.6454 11.7161 33.5093 11.3464C34.3732 10.9766 34.949 10.2505 35.2366 9.1681C35.5242 8.08566 35.385 7.16876 34.819 6.41741C34.253 5.66606 33.2743 5.10474 31.883 4.73345L26.7236 3.35658L24.967 9.96719Z" />
                <path d="M40.8473 35.1481C38.8349 34.4203 37.263 33.3839 36.1318 32.039C35.0006 30.694 34.3239 29.12 34.1017 27.3169C33.905 25.5019 34.1828 23.55 34.935 21.4613C35.4974 19.8995 36.2186 18.5758 37.0984 17.4904C37.985 16.3861 38.9919 15.538 40.1191 14.9461C41.272 14.3422 42.5255 14.0193 43.8798 13.9774C45.2529 13.9423 46.6918 14.1969 48.1964 14.7411C49.306 15.1424 50.3144 15.6773 51.2216 16.3457C52.1476 17.0209 52.8851 17.7768 53.4341 18.6134C53.6793 18.9361 53.7947 19.2649 53.7803 19.6C53.7915 19.9231 53.7121 20.2027 53.542 20.4389C53.3907 20.682 53.1871 20.8636 52.9313 20.9837C52.6822 21.085 52.4038 21.0907 52.0961 21.0007C51.8072 20.9175 51.5342 20.7017 51.277 20.3535C50.8257 19.6586 50.2646 19.0622 49.5936 18.5643C48.9226 18.0664 48.1734 17.6678 47.3458 17.3685C45.9165 16.8515 44.6044 16.7172 43.4095 16.9656C42.2214 17.1952 41.1702 17.7827 40.2558 18.728C39.3414 19.6734 38.5928 20.9552 38.0101 22.5735C37.4273 24.1917 37.1831 25.6666 37.2775 26.9981C37.3788 28.3108 37.8099 29.4451 38.5711 30.4009C39.339 31.338 40.4377 32.065 41.8671 32.582C42.6946 32.8813 43.5252 33.0541 44.3588 33.1004C45.218 33.1347 46.0709 33.039 46.9173 32.8135C47.3304 32.729 47.6652 32.7437 47.9218 32.8578C48.1971 32.9786 48.3983 33.1577 48.5254 33.3951C48.6525 33.6325 48.6968 33.8931 48.6584 34.1769C48.6455 34.4488 48.5412 34.7088 48.3455 34.9571C48.1754 35.1933 47.9154 35.3545 47.5656 35.4406C46.5708 35.7827 45.4892 35.9232 44.3208 35.8621C43.1524 35.8011 41.9945 35.5631 40.8473 35.1481Z" />
            </g>
    )
}
