import React from "react"
import * as models from "../models/index"
import { DonutMathResponse } from "../models/donuts"
import DonutChart from "./DonutChart"
import AdditionIcon from "./DonutIcons/Addition"

export default class AdditionDonut extends React.Component<{
  studentID?: string
  onLoaded?: (donutsResponse: DonutMathResponse) => void
  refresh?: boolean
}, {
  donutData: DonutMathResponse
  loading: boolean
}> {
  state = {
    loading: true,
    donutData: null
  }

  componentDidMount() {
    this.loadDonuts()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.refresh && this.props.refresh) {
      this.loadDonuts()
    }
  }

  loadDonuts = async () => {
    const donutData = await models.donuts.getMathProgress({
      studentID: this.props.studentID
    })

    if (donutData.valid) {
      this.setState({donutData, loading: false}, ()=>{
        if (this.props.onLoaded) {
          this.props.onLoaded(donutData)
        }
      })
    }
  }

  render() {

    if (this.state.loading) {
        return <></>
    }

    var { addition } = this.state?.donutData
    return (
      <div className="text-center">
        <DonutChart
          label="Addition"
          total={addition.total}
          completed={addition.completed}
          baseColor={"#DF554B"}
          Icon={AdditionIcon}
        />
    </div>
    )
  }
}
