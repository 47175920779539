import React from "react"
import BaseIcon from "./BaseIcon";

export default function MultiplicationIcon() {
    return (
        <BaseIcon
            width={33}
            height={33}
        >
            <path d="M25.3756 1.13189L16.2843 10.2233L7.1929 1.13189C6.07731 0.0163042 4.26788 0.0163042 3.15229 1.13189L1.13198 3.1522C0.0163959 4.26778 0.0163959 6.07722 1.13198 7.19281L10.2234 16.2842L1.13198 25.3756C0.0163959 26.4911 0.0163959 28.3006 1.13198 29.4162L3.15229 31.4365C4.26788 32.5521 6.07731 32.5521 7.1929 31.4365L16.2843 22.3451L25.3756 31.4365C26.4912 32.5521 28.3007 32.5521 29.4163 31.4365L31.4366 29.4162C32.5521 28.3006 32.5521 26.4911 31.4366 25.3756L22.3452 16.2842L31.4366 7.19281C32.5521 6.07722 32.5521 4.26778 31.4366 3.1522L29.4163 1.13189C28.3007 0.0163042 26.4912 0.0163042 25.3756 1.13189Z" />
        </BaseIcon>
    )
}
