import qs from 'qs'
/**
 * Returns the GET url parameters on the current page
 * 
 *  * Example:
 *  ```JavaScript
 *  //URL: https://digital.greatleaps.com/probe?number=1
 *  getURLParams()
 *  //Output: { number: 1 }
 *  ```
 */
export default function getURLParams() {
  return qs.parse(window.location.search, { ignoreQueryPrefix: true })
}
