import React from "react"
import BaseIcon from "./BaseIcon";

export default function AdditionIcon() {
    return (
        <BaseIcon
            width={40}
            height={40}
        >
            <path d="M37.1429 15.7143H24.2857V2.85714C24.2857 1.27946 23.0062 0 21.4286 0H18.5714C16.9937 0 15.7143 1.27946 15.7143 2.85714V15.7143H2.85714C1.27946 15.7143 0 16.9937 0 18.5714V21.4286C0 23.0062 1.27946 24.2857 2.85714 24.2857H15.7143V37.1429C15.7143 38.7205 16.9937 40 18.5714 40H21.4286C23.0062 40 24.2857 38.7205 24.2857 37.1429V24.2857H37.1429C38.7205 24.2857 40 23.0062 40 21.4286V18.5714C40 16.9937 38.7205 15.7143 37.1429 15.7143Z" />
        </BaseIcon>
    )
}
