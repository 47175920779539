import modelRequest, { RequestError } from './lib/modelRequest'

interface Login {
  message: 'success!' | string
  valid: boolean
}

export default {
  /**
   * Logs in the user as a student or customer
   */
  post: (
    username: string,
    password: string,
    type: 'student' | 'customer'
  ): Promise<Login & RequestError> => {
    return modelRequest.post('/api/login', {
      username: username,
      password: password,
      type: type,
    })
  },
}
