import React from "react"
import BaseIcon from "./BaseIcon";

export default function DivisionIcon() {
    return (
        <BaseIcon
            width={40}
            height={40}
        >
            <path d="M20 28.5714C16.8438 28.5714 14.2857 31.1295 14.2857 34.2857C14.2857 37.442 16.8438 40 20 40C23.1562 40 25.7143 37.442 25.7143 34.2857C25.7143 31.1295 23.1562 28.5714 20 28.5714ZM20 11.4286C23.1562 11.4286 25.7143 8.87054 25.7143 5.71429C25.7143 2.55804 23.1562 0 20 0C16.8438 0 14.2857 2.55804 14.2857 5.71429C14.2857 8.87054 16.8438 11.4286 20 11.4286ZM37.1429 15.7143H2.85714C1.27946 15.7143 0 16.9937 0 18.5714V21.4286C0 23.0063 1.27946 24.2857 2.85714 24.2857H37.1429C38.7205 24.2857 40 23.0063 40 21.4286V18.5714C40 16.9937 38.7205 15.7143 37.1429 15.7143Z" />
        </BaseIcon>
    )
}
