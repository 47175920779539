import modelRequest, { RequestError } from './lib/modelRequest'

export default {
  /**
   * Logs out customer or student
   */
  post: (): Promise< 'Logged out' | RequestError> => {
    return modelRequest.post('/api/logout')
  }
}
