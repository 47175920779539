import React from "react"
import BaseIcon from "./BaseIcon";

export default function PhrasesIcon() {
    return (
        <BaseIcon
            width={46}
            height={40}
        >
            <path d="M22.7579 0C10.1877 0 0 8.3125 0 18.5714C0 23 1.90242 27.0536 5.06718 30.2411C3.95596 34.7411 0.240024 38.75 0.195575 38.7946C0 39 -0.0533388 39.3036 0.0622285 39.5714C0.177796 39.8393 0.42671 40 0.711183 40C6.60512 40 11.0233 37.1607 13.2102 35.4107C16.1172 36.5089 19.3442 37.1429 22.7579 37.1429C35.328 37.1429 45.5157 28.8304 45.5157 18.5714C45.5157 8.3125 35.328 0 22.7579 0ZM11.3789 21.4286C9.80544 21.4286 8.5342 20.1518 8.5342 18.5714C8.5342 16.9911 9.80544 15.7143 11.3789 15.7143C12.9524 15.7143 14.2237 16.9911 14.2237 18.5714C14.2237 20.1518 12.9524 21.4286 11.3789 21.4286ZM22.7579 21.4286C21.1844 21.4286 19.9131 20.1518 19.9131 18.5714C19.9131 16.9911 21.1844 15.7143 22.7579 15.7143C24.3314 15.7143 25.6026 16.9911 25.6026 18.5714C25.6026 20.1518 24.3314 21.4286 22.7579 21.4286ZM34.1368 21.4286C32.5633 21.4286 31.2921 20.1518 31.2921 18.5714C31.2921 16.9911 32.5633 15.7143 34.1368 15.7143C35.7103 15.7143 36.9815 16.9911 36.9815 18.5714C36.9815 20.1518 35.7103 21.4286 34.1368 21.4286Z" />
        </BaseIcon>
    )
}
