import React from 'react'
import escape from 'escape-html'

function replaceAll(string, toReplace, replaceAs) {
  function escapeRegExp(str) {
    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1')
  }

  return string.replace(new RegExp(escapeRegExp(toReplace), 'g'), replaceAs)
}

function parse(response) {
  let toReplace = {
    '&lt;bold&gt;': '<b>',
    '&lt;/bold&gt;': '</b>',
    '&lt;italic&gt;': '<i>',
    '&lt;/italic&gt;': '</i>',
    '&lt;superscript&gt;': '<sup>',
    '&lt;/superscript&gt;': '</sup>',
    '&lt;subscript&gt;': '<sub>',
    '&lt;/subscript&gt;': '</sub>',
    '\n': '<br />',
    ' -': '&nbsp;-',
  }

  let body = escape(response)

  for (let replace in toReplace)
    body = replaceAll(body, replace, toReplace[replace])

  return body
}

interface Props {
  response: string
  responseNumber: number
  /**
   * Text of the next response, this is only required if noHyphenedSpacing is true
   */
  nextResponse?: string
  onClick?: () => void
  onDoubleClick?: () => void
  /**
   * if this is true then this response had a previous error
   */
  hadPreviousError: boolean
  /**
   * if this is true then hyphened words will not have spaces between them.
   * ie. short-term has two responses in it (short, -term)
   */
  noHyphenedSpacing?: boolean
  /**
   * if this is true then a zero width space is put at the end of the response
   */
  zeroWidthSpace?: boolean
}

/**
 * A single response
 */
function Response({
  response,
  onClick = () => {},
  hadPreviousError,
  responseNumber,
  onDoubleClick = () => {},
  noHyphenedSpacing = false,
  zeroWidthSpace = false,
  nextResponse = null,
}: Props) {
  const spaceBefore = response?.[0] !== '-' && noHyphenedSpacing
  const spaceAfter = nextResponse?.[0] !== '-' && noHyphenedSpacing
  return (
    <span className={`response ${hadPreviousError ? 'previous-error' : ''}`}>
      {spaceBefore && (
        <wbr/>
      )}
      <span
        id={`response-${responseNumber}`}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        className={`response-text ${spaceBefore && "ps-1"} ${spaceAfter && "pe-1"}`}
        dangerouslySetInnerHTML={{ __html: parse(response) }}
      ></span>

      {zeroWidthSpace && <>&#8203;</>}
    </span>
  )
}

export default Response
