import React, { useEffect, useState } from 'react'
import { useTable, useSortBy, useFlexLayout, useResizeColumns, useFilters, useColumnOrder, usePagination, useGlobalFilter, useRowSelect, useAsyncDebounce, useRowState } from 'react-table'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Pagination from 'react-bootstrap/Pagination'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

const defaultHooks = [useColumnOrder,useFlexLayout, useResizeColumns, useFilters, useGlobalFilter, useSortBy, usePagination, useRowSelect, useRowState]
type bulkActionButton = {
    name: string,
    className: string,
    action: (actionArg:any) => void
}
type ReactTable = {
    columns: any[],
    data: any[],
    bulkActions: bulkActionButton[],
    pageSize?: number,
    sortBy?: {id:string,desc?:boolean}[],
    clickRow?: (row:any) => void,
    customClass?: (row:any) => string,
    hideFilter?: boolean,
    hidePagination?: boolean,
}
type Mesa = {
    table: any
    clickRow?: (row:any) => void,
    customClass?: ((row:any) => string) | undefined
    usePagination?: boolean
}
/**
 * 
 * @param table table object generated from react table
 * @abstract A bootstrap table with react table functionality built in for sorting and resizing
 * NOTE: Using Mesa since "Table" is taken by bootstrap 
 */
function Mesa({ table, clickRow=()=>{}, customClass=()=>"", usePagination=true }: Mesa) {
    function saveSortLocally(column: any) {
        // isSortedDesc is the current sorting value BEFORE the click, so if val was NOT desc and was sorted, it was asc sorted
        const sortDescending = column.isSorted && !column.isSortedDesc 
        localStorage.setItem(column.render('Header'), (sortDescending).toString())
    }
    function isSortedDesc(column: any) {
        const savedSort = localStorage.getItem(column.render('Header').toString())
        if(savedSort) return (savedSort === "true")
        else return column.isSortedDesc
    }
    return (
        <Table responsive striped bordered hover {...table.getTableProps()}>
            <thead>
                {table.headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className="d-flex flex-row justify-content-between">
                            {column.render('Header')}
                            <div {...column.getResizerProps()} 
                                className={`resizer 
                                    ${column.isResizing ? 'isResizing' : ''}`
                                }
                            >
                            </div>
                            <span className={`${column.canSort ? "" : "d-none"}`} onClick={() => saveSortLocally(column)} >
                                {column.isSorted ? 
                                    (isSortedDesc(column) ? <i className="fas fa-caret-down ms-1"/> : <i className="fas fa-caret-up ms-2"/>)
                                    : 
                                    <div className="d-flex flex-column">
                                        <i className="fas fa-caret-up ms-2"/>
                                        <i className="fas fa-caret-down ms-2"/>
                                    </div>
                                }
                            </span>
                        </th>
                    ))}
                </tr>
                ))}
            </thead>
            <tbody {...table.getTableBodyProps()}>
                <>
                    {(usePagination ? table.page : table.rows).map((row: any, i: number) => {
                        table.prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} onClick={() => clickRow(row)} className={customClass(row)}>
                                {row.cells.map((cell: any) => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </>
            </tbody>
        </Table>
    )
}
/**
 * @param table object generated from react table
 * @abstract a filter that searches all columns 
 */
function GlobalFilter({ table, resetPagination=true }: { table: any, resetPagination?: boolean }) {
    const count = table.preGlobalFilteredRows.length
    const [value, setValue] = React.useState(table.state.globalFilter)
    const onChange = useAsyncDebounce((value: any) => {
        resetPagination && table.gotoPage(0)
        table.setGlobalFilter(value || undefined)
    }, 200)
  
    return (
        <input
          value={value || ""}
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`Search ${count} row${count > 1 ? 's' : ''}`}
          className="form-control table-search"
        />
    )
}
/**
 * @param columns the column format for the table
 * @param data the rows of the table
 * @param bulkActions desired buttons on top of table that may be clicked when selecting row(s)
 * @abstract A wrapper for react table with additional functionality for bulk actions on the table rows. 
 */
function ReactTable({ columns, data, bulkActions, pageSize, sortBy, clickRow, customClass, hideFilter, hidePagination}: ReactTable) {

    const [selected,setSelected] = useState([])
    const defaultColumn = React.useMemo(() => ({
        minWidth: 30,
        width: 150, 
        maxWidth: 300, 
    }),[])
    const hooks = hidePagination ? [...defaultHooks].filter((hook) => hook !== usePagination ) : defaultHooks
    const defaultSortBy = React.useMemo(() => ([]),[]) // no sorting on default (react-table requires it to be memoized)
    const table = useTable({ // table will have a type when upgraded to version 8
        columns,
        data,
        defaultColumn,
        //getTrProps: getTrProps,
        autoResetPage: false,
        autoResetRowState: false,
        getRowId: (rowData: any) => rowData.id,
        initialState: { 
            pageIndex: 0, 
            pageSize: pageSize ?? 20,
            sortBy: sortBy ?? defaultSortBy
        } // page size tells react table pagination how many rows are in a page
    }, ...hooks)

    useEffect(() => {
        setSelected(table.selectedFlatRows)
    },[data])

    useEffect(() => {
        selected.forEach((row: any) => {
            table.toggleRowSelected(row.id,true)
        })
    },[table.selectedFlatRows])

    const selectedCount = table.selectedFlatRows.length
    const hideRowSelect = selectedCount === 0

    return (<div className="react-table">
        <div className="d-flex justify-content-between my-2 w-100">
            <div className='d-flex align-items-center'>
                <span className={`${hideRowSelect && "d-none"} font-sm me-2`}>
                    {selectedCount} row{selectedCount === 1 ? '' : 's'} selected
                </span>
                {bulkActions.map((button: bulkActionButton, i: number) => {
                    return (
                        <OverlayTrigger key={button.name} overlay={
                            <Tooltip id={`tooltip-disabled-${button.name}-${i}`} className={`${hideRowSelect ? "tooltip-disabled" : "d-none"}`}>
                                Please select a row
                            </Tooltip>
                        }>
                            <span>
                                <Button onClick={() => button.action(table.selectedFlatRows.map((row: any)=>row.original))} 
                                    disabled={table.selectedFlatRows.length > 0 ? false : true} 
                                    variant="outline-dark" 
                                    className={`${button.className} ${selectedCount > 0 ? "" : "pe-none"} mx-2`}
                                >
                                    {button.name}
                                </Button>
                            </span>
                        </OverlayTrigger>
                    )
                })}
            </div>
            <div className={`${hideFilter && "d-none"} col-6 col-lg-4 dont-print`}>
                <GlobalFilter table={table} resetPagination={!hidePagination} />
            </div>
        </div>
        <Mesa table={table} clickRow={clickRow} customClass={customClass} usePagination={!hidePagination}/>
        {!hidePagination && 
            <Pagination className={`d-flex justify-content-center fade ${table.pageCount > 1 && "show"}`}>
                {table.pageCount > 2 && 
                    <Pagination.First onClick={() => table.gotoPage(0)} disabled={!table.canPreviousPage} />
                }
                <Pagination.Prev onClick={() => table.previousPage()} disabled={!table.canPreviousPage}>Previous</Pagination.Prev>
                {Array.from(Array(table.pageCount)).map((_,pageNum) => 
                    ((Math.abs(table.state.pageIndex-pageNum) < 3) && 
                        <Pagination.Item onClick={() => table.gotoPage(pageNum)} className={`${((pageNum) === table.state.pageIndex) && "active"}`} key={pageNum}>{pageNum+1}</Pagination.Item>
                    )
                )}
                <Pagination.Next onClick={() => table.nextPage()} disabled={!table.canNextPage}>Next</Pagination.Next>
                {table.pageCount > 2 && 
                    <Pagination.Last onClick={() => table.gotoPage(table.pageCount - 1)} disabled={!table.canNextPage} />
                }
            </Pagination>
        }
    </div>)
}

export default ReactTable
