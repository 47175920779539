import React from "react"
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"
import { useFormikContext, useField  } from "formik"

interface Props {
    /**
     * Adds a input group text to end of input
     *
     * Note: This component must be wrapped in a `InputGroup` when this prop is used
     */
    groupText?: string
}

/**
 * Hooks up a react-bootstrap control with formik.
 *
 * Things this handles:
 * - Sets up the formik events
 * - disables the field while the form is submitting
 * - Highlight and shows errors when there is one
 *
 * Example:
 * ```tsx
 * <FormikControl type="text" name="name" />
 * ```
 */
export default function FormikControl({groupText, ...rest}: React.ComponentProps<typeof Form.Control & Props>) {
    const { isSubmitting } = useFormikContext()
    const [field, meta] = useField(rest.name)
    const isInvalid = !!meta.error && meta.touched
    return (<>
        <Form.Control
            disabled={isSubmitting}
            isInvalid={isInvalid}
            {...field}
            {...rest}
        />
        {groupText &&
            <InputGroup.Text>{groupText}</InputGroup.Text>
        }
        {isInvalid &&
            <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
        }
        </>
    )
}
