import React from "react"
import BaseIcon from "./BaseIcon";

export default function SubtractionIcon() {
    return (
        <BaseIcon
            width={36}
            height={41}
        >
            <path d="M35.0121 28.125V1.875C35.0121 0.835937 34.1759 0 33.1365 0H7.5026C3.36054 0 0 3.35938 0 7.5V32.5C0 36.6406 3.36054 40 7.5026 40H33.1365C34.1759 40 35.0121 39.1641 35.0121 38.125V36.875C35.0121 36.2891 34.7386 35.7578 34.3166 35.4141C33.9883 34.2109 33.9883 30.7813 34.3166 29.5781C34.7386 29.2422 35.0121 28.7109 35.0121 28.125ZM10.0035 10.4688C10.0035 10.2109 10.2145 10 10.4724 10H27.0406C27.2985 10 27.5095 10.2109 27.5095 10.4688V12.0312C27.5095 12.2891 27.2985 12.5 27.0406 12.5H10.4724C10.2145 12.5 10.0035 12.2891 10.0035 12.0312V10.4688ZM10.0035 15.4688C10.0035 15.2109 10.2145 15 10.4724 15H27.0406C27.2985 15 27.5095 15.2109 27.5095 15.4688V17.0312C27.5095 17.2891 27.2985 17.5 27.0406 17.5H10.4724C10.2145 17.5 10.0035 17.2891 10.0035 17.0312V15.4688ZM29.8072 35H7.5026C6.11931 35 5.00173 33.8828 5.00173 32.5C5.00173 31.125 6.12712 30 7.5026 30H29.8072C29.6587 31.3359 29.6587 33.6641 29.8072 35Z" />
        </BaseIcon>
    )
}
